import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavForPages } from '../components';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>JETSOMS</title>
        <meta
          name="description"
          content="Please contact us by filling out the short form"
        />
      </Helmet>

      <section className="page">
        {/* navbar */}
        <NavForPages />

        <div className="page-center contact-page">
          {/* title */}
          <div className="section-title page-title">
            <h2>
              <span>contact</span> me
            </h2>
            <div className="underline"></div>
            <p>
              If you want to work with us please fill out the short form below and we will be in touch shortly.
              <br />
              Alternatively, you can also drop us an email at{' '}
              <a href="mailto:enquiries@jetsoms.co.uk" title="Email us">
                enquiries@jetsoms.co.uk
              </a>
            </p>
          </div>

          {/* form */}
          <div className="section-center">
            <form
              className="form"
              action="https://formspree.io/f/"
              method="POST"
            >
              <div className="form-center">
                {/* name  */}
                <article>
                  <label htmlFor="name">name</label>
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    placeholder="John Doe"
                    required
                  />
                </article>
                {/* email */}
                <article>
                  <label htmlFor="email">e-mail</label>
                  <br />
                  <input
                    type="email"
                    className="form-control"
                    name="Email"
                    placeholder="johndoe123@mail.com"
                    required
                  />
                </article>
                {/* text area */}
                <article>
                  <label htmlFor="message">how can I help?</label>
                  <br />
                  <textarea
                    name="Message"
                    className="form-control"
                    placeholder="We run a small startup and we want a website for the same..."
                    rows="8"
                    required
                  ></textarea>
                </article>
              </div>
              <div className="btn-container">
                <button className="btn submit-btn" type="submit">
                  submit 🛴
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
