import React from 'react';
import heroImg from '../images/hero/hero-img.svg';
import { AppContext } from '../context';

export const Hero = () => {
  // function from context
  const { smoothScroll } = React.useContext(AppContext);

  // jsx
  return (
    <header id="home">
      <div className="banner">
        <div className="banner-container section-center">
          {/* hero-info */}
          <article className="hero-info">
            <h1 id="my-name">
              Hey,<span>we're Jetsoms</span>
            </h1>
            <p>
              Recruitment Insurance Specialists, experience spanning 12+ years!
            </p>
            <div className="hero-btn-container">
              <a
                href="mailto:enquiries@jetsoms.co.uk"
                className="btn hero-btn"
                title="Email us..."
              >
                get in touch 📞
              </a>
              <a
                href="#projects"
                className="btn hero-btn"
                onClick={(e) => smoothScroll(e)}
                title="My Latest Works"
              >
                see my work 💻
              </a>
            </div>
          </article>

          {/* hero-image */}
          <article className="hero-img">
            <img
              src={heroImg}
              alt="Hero"
              className="hero-image"
              loading="lazy"
            />
          </article>
        </div>
      </div>
    </header>
  );
};
