import {
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaGithub,
  FaCodepen,
  FaYoutube,
} from 'react-icons/fa';

// **************************
// social icons
// **************************
export const socialIcons = [
  {
    id: 1,
    icon: <FaInstagram className="fa instagram" />,
    url: 'https://www.instagram.com/',
    title: 'Instagram',
  },
  {
    id: 2,
    icon: <FaTwitter className="fa twitter" />,
    url: 'https://twitter.com/',
    title: 'Twitter',
  },

  {
    id: 3,
    icon: <FaYoutube className="fa youtube" />,
    url: 'https://youtube.com/',
    title: 'YouTube',
  },
  {
    id: 4,
    icon: <FaLinkedin className="fa linkedin" />,
    url: 'https://www.linkedin.com/in/recruitmentagencyinsurance',
    title: 'LinkedIn',
  },
  {
    id: 5,
    icon: <FaGithub className="fa github" />,
    url: 'https://github.com/',
    title: 'GitHub',
  },
  {
    id: 6,
    icon: <FaCodepen className="fa codepen" />,
    url: 'https://codepen.io/',
    title: 'Codepen',
  },
];

// **************************
// nav links
// **************************
export const navLinks = [
  {
    id: 1,
    url: '#home',
    text: 'home',
  },
  {
    id: 2,
    url: '#about',
    text: 'about',
  },
  {
    id: 3,
    url: '#projects',
    text: 'projects',
  },
  {
    id: 4,
    url: '#contact',
    text: 'contact',
  },
];
